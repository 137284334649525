import Vue from 'vue'
import VueRouter from 'vue-router'

import FrontLayout from "@/layouts/FrontLayout";
Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',
    linkActiveClass: 'active',
    routes: [
        {
            path:'/',
            component: FrontLayout,
            children: [
                {
                    path: '/',
                    component: () => import('./pages/Dashboard/Dashboard.vue'),
                    name: 'Dashboard'
                },
                {
                    path: '/terminals',
                    component: () => import('./pages/Terminals/Terminals.vue'),
                    name:'Terminals'
                },
                {
                    path: '/users',
                    component: () => import('./pages/Users/Users.vue'),
                    name: 'Users'
                },
                {
                    path: '/reports',
                    component: () => import('./pages/Reports/Reports.vue'),
                    name: 'Reports'
                },
                {
                    path: '/history',
                    component: () => import('./pages/History/History.vue'),
                    name: 'History'
                },
                {
                    path: '/errorCode',
                    component: () => import('./pages/ErrorCode/ErrorCode'),
                    name: 'ErrorCode'
                },

            ],


        },
        {
            path:'/login',
            component: () => import('./Login/login'),
            name:'Login',
            meta:{
                public:true
            }
        }
    ]
})

export default router;