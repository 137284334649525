import Vue from 'vue';
import Vuex from 'vuex';
import router from "./router";
import TokenService from "./services/TokenService";

import http from "./plugins/Axios";

Vue.use(Vuex)
const store = new Vuex.Store({
    state: {
        loader: false,
        url: '',
        roleId:localStorage.getItem('role') || 0
    },
    mutations: {
        loader(state, value) {
            state.loader = value;
        },
    },
    actions: {
        async logOut() {
            TokenService.removeToken();
            await router.push('/login')
        },
        async getUsers(context, params = {page: 1, count: 10}) {
            return await http.get('Admin/GetUsers', {params: params})
        },
        async getErrorList(context, params) {
            return await http.get('Admin/GetErrorsDictionary', {params: params})
        },
        async getRole(context) {
            return await http.get('Admin/GetRoles');
        },
        async getStatuses(context) {
            return await http.get('Admin/GetUserStatuses');
        },
        async getPaymentTypes(context) {
            return await http.get('Admin/GetPaymentTypes');
        },
        async getPaymentStatuses(context) {
            return await http.get('Admin/GetPaymentStatuses');
        },
        async getTokenStatuses(context) {
            return await http.get('Admin/GetTokenStatuses');
        },
        async getReportTypes(context) {
            return await http.get('Admin/GetReportTypes');
        },
        async getDashboards(context,params){
            return await http.get('Report/GetUserStatistics',{params: params});
        }


    },
    getters: {},

})
export default store