export default {
    methods: {
        showLoader() {
            this.$store.commit('loader', true);
        },
        hideLoader() {
            this.$store.commit('loader', false);
        },
        getNameById(list = [], id,  keyName = 'name') {
            if (list.length > 0) {
                let item = list.find(x => x.id === id);
                return item ?  item[keyName] : id;
            }
        }
    }
}