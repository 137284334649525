import Vue from 'vue';
import Moment from 'moment';


Vue.filter('commonDateFormat', (val) => {
    return Moment(val).isValid() ? Moment(val).format('DD.MM.YYYY HH:mm') : '';
});
Vue.filter('dateMonthYearFormatter', (val) => {
    return Moment(val).isValid() ? Moment(val).format('DD MMM')+ ', ' + Moment(val).format('YYYY') : '';
});
Vue.filter('dateOnlyTime', (val) => {
    return Moment(val).isValid() ? Moment(val).format('HH:mm') : '';
});
Vue.filter('differentDay', function (value, different) {
    let a = Moment(value);
    let b = Moment(different);
    return (a.diff(b, 'days'));
});
Vue.filter('financialFormatMoney', function (value) {
    let val = (value/1).toFixed(2)
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
});