<template>
  <div class="header-view">
    <!--  <div class="header-notification">-->
    <!--    <div class="dropdown">-->
    <!--      <button class="dropdown-toggle header-dropdown-button" type="button" id="dropdownNotification" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
    <!--        <img src="../../../public/img/notification.png" alt="">-->
    <!--        <div class="notification-total">12</div>-->
    <!--      </button>-->
    <!--      <div class="dropdown-menu" aria-labelledby="dropdownNotification">-->
    <!--      </div>-->
    <!--    </div>-->

    <!--  </div>-->
    <div class="header-users">
      <div class="dropdown">
        <button class="dropdown-toggle header-dropdown-button" type="button" id="dropdownMenuButton"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <p class="m-0">{{ userName }}</p>
          <span class="users-role">{{ userRole }}</span>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item text-center" href="#">Настройки</a>
          <a class="dropdown-item text-center cursor-pointer" @click="logOut()">Выйти</a>
        </div>
      </div>
      <img src="../../../public/img/dropdown-icon.png" alt="" class="header-users-dropdown-icon">
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      userRole: ''
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('logOut');
    },
    async getUserRole() {
      const res = await this.$store.dispatch('getRole');
      this.userRole = this.getNameById(res.result.roles, Number(localStorage.getItem('role')));
    }
  },
  computed: {
    userName() {
      return localStorage.getItem('userName');
    },
  },
  created() {
    this.getUserRole();
  }
}
</script>

<style scoped>
</style>