<template>
<div class="front-view">
  <Sidebar/>
  <Header/>
<div class="main-content">
  <router-view></router-view>
</div>
</div>
</template>

<script>
import Sidebar from "@/components/Sidebar/Sidebar";
import Header from "@/components/Header/Header";
export default {
name: "FrontLayout",
  components:{
    Sidebar,
    Header
  }
}
</script>

<style scoped>

</style>