import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router'
import store from './store'
import Vuex from 'vuex';
import http from "./plugins/Axios";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Notifications from 'vue-notification';
import NotificationMixin from "./mixins/Notifications";
import helpers from "./mixins/helpers";
import Vuelidate from 'vuelidate'
import vPagination from 'vue-plain-pagination'
import './mixins/filters';
import JsonExcel from "vue-json-excel";


Vue.prototype.$http = http;

Vue.component("downloadExcel", JsonExcel);
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Notifications);
Vue.use(Vuelidate);
Vue.use(require('vue-moment'));

Vue.mixin(NotificationMixin);
Vue.mixin(helpers);


Vue.component('v-pagination', vPagination)

Vue.config.productionTip = false;

new Vue({
  el:'#app',
  router,
  store,
  render: h => h(App)
})
