<template>
  <div id="app">
    <router-view></router-view>
    <loader v-if="$store.state.loader"></loader>
    <notifications group="foo" />
  </div>
</template>

<script>

import Loader from "@/components/Loader/loader";
export default {
  name: 'App',
  components:{
    Loader
  }

}
</script>

<style>
</style>
