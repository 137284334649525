<template>
<div class="sidebar-view">
  <div class="sidebar-logo">
    <img src="../../../public/img/logo.png" alt="Pos/Epos">
  </div>
  <div class="sidebar-content">
      <router-link class="sidebar-item" tag="div" to="/" v-if="roleId != 3">
        <img src="../../../public/img/dashboard.png" alt="">
          <p>Dashboard</p>
      </router-link>
    <router-link class="sidebar-item" tag="div" to="/users" v-if="roleId != 3">
      <img src="../../../public/img/usersIcon.png" alt="">
      <p>Пользователи</p>
    </router-link>
    <router-link class="sidebar-item" tag="div" to="/terminals">
      <img src="../../../public/img/terminalIcon.png" alt="">
      <p>Терминалы</p>
    </router-link>
    <router-link class="sidebar-item" tag="div" to="/reports" v-if="roleId != 3">
      <img src="../../../public/img/otchyotIcon.png" alt="">
      <p>Отчетность</p>
    </router-link>
    <router-link class="sidebar-item" tag="div" to="/history">
      <img src="../../../public/img/istoryIcon.png" alt="">
      <p>История</p>
    </router-link>
    <router-link class="sidebar-item" tag="div" to="/errorCode" v-if="roleId != 3">
      <i class="icon-error_outline"></i>
      <p>Коды Ошибок</p>
    </router-link>


  </div>
</div>
</template>

<script>
export default {
name: "Sidebar",
  data(){
    return{
      roleId :localStorage.getItem('role')
    }
  },
  created() {
    console.log(this.roleId)
  }
}
</script>
<style scoped>

</style>
